@import '../../base/base';
.swal2-popup {
  background-color: #fff !important;
}

.swal2-title {
  color: $dark;
}

.swal2-html-container {
  color: #e95f2b;
}

.swal2-styled {
  &.swal2-default-outline:focus, &.swal2-confirm:focus {
    box-shadow: none;
  }
}

  
.swal2-icon {
  
  &.swal2-success {
    
    .swal2-success-ring {
      border-color: #ddf5f0;
    }

    [class^=swal2-success-line] {
      background-color: #00ab55;
    }
    
  }

  &.swal2-error {
    border-color: #fbeced;

    [class^=swal2-x-mark-line] {
      background-color: $danger;
    }
    
  }


  &.swal2-warning {
    border-color: #fcf5e9;
    color: $warning;
  }

  &.swal2-info {
    border-color: #e6f4ff;
    color: $info;
  }

  &.swal2-question {
    border-color: #f2eafa;
    color: $secondary;
  }
  
}

